import { InjectionToken, NgModule } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { TradeTicketService, WindowService } from '@app/etfs-equities/services';
import { environment } from '@env/environment';
import { APP_NAME, APP_PREFIX } from '@env/environment.constants';
import {
  AngularOpentelemetryModule,
  BaggageConstants,
  BaggageConstantsBaggageProvider,
  COLLECTOR_ENVIRONMENT,
  COLLECTOR_LOCATION,
  DocumentLoadInstrumentationModule,
  FetchInstrumentationModule,
  InjectionTokenProvider,
  JourneyIdProviderBuilder,
  SpanAssociationType,
  stringOfLength,
  stripQueryStringAndHashFromPath,
  XHRInstrumentationModule,
} from '@vanguard/invest-otel-lib/angular';
import { InvestSemanticAttributes } from '@vanguard/invest-semantic-conventions';
import expressUserAgent from 'express-useragent';
import { v4 as uuidv4 } from 'uuid';

import { ROUTES } from '../../etfs-equities/constants';
import { UserService } from '../../etfs-equities/services/user/user.service';
import { applyAttributesOnFetch, applyAttributesOnXHR } from './tracing.util';

const SESSIONID = new InjectionToken<string>('SESSIONID');

const ignoreUrls = [/.*\/tealeaf.*/, environment.prospectus.endpointPath];

/**
 * propagateTraceHeaderCorsUrls Allows baggage and trace parent headers to be sent to the url's contained in this list
 */
const PROPAGATE_TRACER_HEADER_CORS_URLS = [
  new RegExp(`${environment.apiUrl}.*`),
  new RegExp(`${environment.vgaInvestApiUrl}.*`),
  new RegExp(`${environment.aizWebServiceURL}.*`),
];

const STAGE_MAP: Record<ROUTES | string, string> = {
  ['/']: 'Root',
  [ROUTES.TRADE_PATH]: 'Trade',
  [ROUTES.EXTENDED_TRADING_PATH]: 'EveningTrade',
  [ROUTES.CONFIRMATION_PATH]: 'Confirm',
  [ROUTES.PREVIEW_PAGE_PATH]: 'Preview',
  [ROUTES.EXTENDED_TRADING_PREVIEW_PAGE_PATH]: 'EveningPreview',
  [ROUTES.OPEN_ORDERS_PATH]: 'Orders',
  [ROUTES.EDIT_COST_BASIS_PATH]: 'EditCostBasis',
  [ROUTES.CANCEL_PATH]: 'Cancel',
  [ROUTES.CANCEL_SUCCESS_PATH]: 'CancelSuccess',
  [ROUTES.HOLDINGS_PATH]: 'Holdings',
  [ROUTES.SELECT_SHARES_PATH]: 'SelectShares',
};

@NgModule({
  imports: [
    DocumentLoadInstrumentationModule.forRoot({}),
    FetchInstrumentationModule.forRoot({
      ignoreUrls,
      applyCustomAttributesOnSpan: applyAttributesOnFetch,
      propagateTraceHeaderCorsUrls: PROPAGATE_TRACER_HEADER_CORS_URLS,
    }),
    XHRInstrumentationModule.forRoot({
      ignoreUrls,
      applyCustomAttributesOnSpan: applyAttributesOnXHR,
      propagateTraceHeaderCorsUrls: PROPAGATE_TRACER_HEADER_CORS_URLS,
    }),
    AngularOpentelemetryModule.forRoot({
      tracerName: 'TWE Tracer',
      tracerVersion: '1.0.0',
      deploymentConfig: {
        useFactory: (collectorEnvironment, collectorLocation) => ({ collectorEnvironment, collectorLocation }),
        deps: [COLLECTOR_ENVIRONMENT, COLLECTOR_LOCATION],
      },
      resourceAttributes: {
        useFactory: (sessionId: string, windowService: WindowService) => ({
          [InvestSemanticAttributes.SERVICE_NAME]: APP_NAME,
          [InvestSemanticAttributes.SERVICE_APP_PREFIX]: APP_PREFIX,
          [BaggageConstants.VG_REFERRER_SESSION_ID]: sessionId,
          [BaggageConstants.VG_REFERRER]: stripQueryStringAndHashFromPath(window.location.toString()),
          [BaggageConstants.VG_CHANNEL]: windowService.getChannelType(),
        }),
        deps: [SESSIONID, WindowService],
      },
      tracerOptions: { spanAssociationType: SpanAssociationType.NONE },
      baggageProvider: {
        useFactory: (
          sessionId: string,
          windowService: WindowService,
          tradeTicketService: TradeTicketService,
          router: Router
        ) => {
          return BaggageConstantsBaggageProvider.for({
            referrerSessionId: stringOfLength(sessionId, 1, 60),
            referrer: () => stringOfLength(stripQueryStringAndHashFromPath(window.location.toString()), 1, 150),
            channel: stringOfLength(windowService.getChannelType(), 1, 10),
            journeyId: () => {
              const businessArea = 'trade';
              const queryParams = windowService.getSearch();

              const url = stripQueryStringAndHashFromPath(router.url);

              const stage =
                url === ROUTES.TRADE_PATH && queryParams.has('orderId') && queryParams.has('accountId')
                  ? 'ChangeOrder'
                  : STAGE_MAP[url];

              const orderAction = tradeTicketService.getOrderAction() ?? 'unknown';

              const flow = `equityetf_${orderAction}`;

              //https://confluence.vanguard.com/display/RETAILSRE/Journey+Identification+and+what+it+can+solve
              //https://confluence.vanguard.com/display/RETSRECOP/Journey+Tracing+Baggage+Spec

              //Max length of journey_id is 60 chars, including separators (5 colons)
              //and leading version (1 char).
              // An exception WILL be throw if length is exceeded!!!!
              return JourneyIdProviderBuilder.create({
                businessArea,
                flow,
                //  flowVariation: getServerlessFolder(new URL(window.location.href)) ?? 'NA',
                stage: stage ?? 'unknown',
              });
            },
          });
        },
        deps: [SESSIONID, WindowService, TradeTicketService, Router],
      },
      locationProvider: {
        getLocation: () => window.location.href,
      },
      spanProcessorListener: {
        useFactory: (userService: UserService, meta: Meta, sessionId: string) => {
          return (span) => {
            const revisionMeta = meta.getTag('name=revision');

            const userAgent = expressUserAgent.parse(navigator.userAgent);

            span.setAttribute(`${APP_PREFIX}.sessionId`, sessionId);
            span.setAttribute('spoid', userService.getSpoid());
            span.setAttribute('poid', userService.getPoid());
            span.setAttribute('isMobile', userAgent.isMobile);
            span.setAttribute('isTablet', userAgent.isTablet);
            span.setAttribute('platform', userAgent.platform);
            span.setAttribute('operatingSystem', userAgent.os);
            span.setAttribute('browser', userAgent.browser);
            span.setAttribute('browserVersion', userAgent.version);
            span.setAttribute('revision', revisionMeta?.content);
          };
        },
        deps: [UserService, Meta, SESSIONID],
      },
    }),
  ],
  providers: [InjectionTokenProvider.for(SESSIONID, uuidv4())],
})
export class OpentelemetryModule {}
