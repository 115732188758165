import { Span } from '@opentelemetry/api';
import { FetchError } from '@opentelemetry/instrumentation-fetch/build/src/types';
import { applyCustomAttributesOnFetch, applyCustomAttributesOnXHR } from '@vanguard/invest-otel-lib/angular';

interface CustomParameters {
  pathname: string;
  queryObj: Record<string, string>;
}

const parseUrl = (url: string): CustomParameters => {
  const urlObj = new URL(url);
  const { pathname, search } = urlObj;
  const querySearch = new URLSearchParams(search);
  const queryObj = Object.fromEntries(querySearch);

  return {
    pathname: pathname?.replace(/^\/twe-trade-web-equity-etf/i, ''),
    queryObj,
  };
};

export const applyAttributesOnFetch = (
  span: Span,
  request: Request | RequestInit,
  response: Response | FetchError
): void => {
  // apply custom attributes
  applyCustomAttributesOnFetch(span, request, response);

  let params: CustomParameters;

  if (request instanceof Request && request.url) {
    params = parseUrl(request.url);
  }

  if (response instanceof Response && response.url) {
    params = parseUrl(response.url);
  }

  if (params) {
    span.setAttribute('http.route', params.pathname);
    span.setAttribute('http.query', JSON.stringify(params.queryObj));
  }
};

export const applyAttributesOnXHR = (span: Span, xhr: XMLHttpRequest): void => {
  // apply custom attributes
  applyCustomAttributesOnXHR(span, xhr);

  const url = parseUrl(xhr.responseURL);

  span.setAttribute('http.route', url.pathname);
  span.setAttribute('http.query', JSON.stringify(url.queryObj));
};
